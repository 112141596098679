import React from 'react'
import { Link } from 'react-router-dom'

export default function Rekap() {
    return (
        <div className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">Rekap Laporan</div>
            <div className="mt-8 font-mp-bold">
                <div className="grid grid-cols-2 gap-4">
                    <Link to="/rekap/rekappolda" className="flex flex-col bg-white px-6 py-6 overflow-hidden rounded-lg border border-gray-dark shadow-lg shadow-gray-box">
                        <div className="text-base text-center">Rekap POLDA</div>
                    </Link>
                    <Link to="/rekap/rekapplbn" className="flex flex-col bg-white px-6 py-6 overflow-hidden rounded-lg border border-gray-dark shadow-lg shadow-gray-box">
                        <div className="text-base text-center">Rekap PLBN</div>
                    </Link>
                    <Link to="/rekap/rekapmanual" className="flex flex-col bg-white px-6 py-6 overflow-hidden rounded-lg border border-gray-dark shadow-lg shadow-gray-box">
                        <div className="text-base text-center">Rekap Manual</div>
                    </Link>
                    <Link to="/rekap/rekappoldabtnclo" className="flex flex-col bg-white px-6 py-6 overflow-hidden rounded-lg border border-gray-dark shadow-lg shadow-gray-box">
                        <div className="text-base text-center">Rekap POLDA BTNCLO</div>
                    </Link>
                    <Link to="/rekap/rekapmanualbtnclo" className="flex flex-col bg-white px-6 py-6 overflow-hidden rounded-lg border border-gray-dark shadow-lg shadow-gray-box">
                        <div className="text-base text-center">Rekap Manual BTNCLO</div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
