import React, { useCallback, useContext, useEffect, useState } from 'react'
import ApexCharts from 'react-apexcharts';
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import axios from '../../api/axios';

const RekapManualBtnclo = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const [loading, setLoading] = useState(true);
    const [btncloOptions, setBtncloOptions] = useState([]);
    const [formData, setFormData] = useState({
        btnclo: '',
        start_date: '',
        end_date: '',
    });

    const [errors, setErrors] = useState({});
    const [chartData, setChartData] = useState({
        poldaBTNCLO: {},
        kategoriLaporan: {},
        laporanBerkala: {},
        laporanInsidentil: {},
        totalLaporan: 0,
    });
    
    const [dataSubmitted, setDataSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' });
    };

    const formatDate = (date) => {
        return new Date(date).toISOString().split('T')[0];
    };

    const fetchData = useCallback(async () => {
        try {
            const { btnclo, start_date, end_date } = formData;
            if (!start_date || !end_date || !btnclo) return;

            // Format dates
            const formattedStartDate = formatDate(start_date);
            const formattedEndDate = formatDate(end_date);

            const response = await axios.get(
                `${baseURL}/api/v1/btnclo/rekap_manual/${formattedStartDate}&${formattedEndDate}&${btnclo}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            const data = response.data;

            setChartData({
                poldaBTNCLO: data.btnclo_polda || {},
                kategoriLaporan: data.kategori_laporan || {},
                laporanBerkala: data.laporan_berkala || {},
                laporanInsidentil: data.laporan_insidentil || {},
                totalLaporan: data.total_laporan || 0,
                berkalaTotal: response.data.kategori_laporan?.berkala || 0,
                insidentilTotal: response.data.kategori_laporan?.insidentil || 0,
            });

            setLoading(false);
            setDataSubmitted(true);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }, [baseURL, formData, token]);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.btnclo) {
            newErrors.btnclo = 'BTNCLO is required';
        }
        if (!formData.start_date) {
            newErrors.start_date = 'Start date is required';
        }
        if (!formData.end_date) {
            newErrors.end_date = 'End date is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            fetchData();
        }
    };

    useEffect(() => {
        // Fetch instansi options on mount
        const fetchInstansiOptions = async () => {
            try {
                const btncloResponse = await axios.get(`${baseURL}/api/v1/btnclo/dropdown_btnclo`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                setBtncloOptions([
                    { value: '', label: '-- Pilih Opsi --' },
                    ...btncloResponse.data.btnclo.map(btnclo => ({
                        value: btnclo.btnclo,
                        label: btnclo.btnclo,
                    })),
                ]);
            } catch (error) {
                console.error('Error fetching instansi options:', error);
            }
        };

        fetchInstansiOptions();
    }, [baseURL, token]);

    return (
        <section className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">Rekap Manual BTNCLO</div>
            <div className="mt-8">
            <form onSubmit={handleSubmit} className="flex flex-col gap-8 laptop:grid laptop:grid-cols-2 laptop:gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
                        <div className="flex flex-col gap-6 px-4">
                            <div className="text-lg font-mp-bold justify-start">
                                BTNCLO & Range Tanggal
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="btnclo" className="block text-base leading-6 text-gray">BTNCLO</label>
                                <div className="mt-2">
                                    <FormSelect
                                        name="btnclo"
                                        value={formData.btnclo}
                                        handleChange={handleChange}
                                        options={btncloOptions}
                                    />
                                    {errors.instansi && <span className="text-red text-sm">{errors.btnclo}</span>}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="start_date" className="block text-base leading-6 text-gray">Dari</label>
                                <div className="mt-2">
                                    <input
                                        type="date"
                                        name="start_date"
                                        id="start_date"
                                        autoComplete="given-name"
                                        value={formData.start_date}
                                        onChange={handleChange}
                                        className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-box focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors.start_date ? 'ring-red-500' : ''}`}
                                    />
                                    {errors.start_date && <span className="text-red text-sm">{errors.start_date}</span>}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="end_date" className="block text-base leading-6 text-gray">Sampai</label>
                                <div className="mt-2">
                                    <input
                                        type="date"
                                        name="end_date"
                                        id="end_date"
                                        autoComplete="given-name"
                                        value={formData.end_date}
                                        onChange={handleChange}
                                        className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-dark focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors.end_date ? 'ring-red-500' : ''}`}
                                    />
                                    {errors.end_date && <span className="text-red text-sm">{errors.end_date}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row justify-end gap-3 mt-8">
                            <button type="submit" className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg">Submit</button>
                        </div>
                    </div>
                    {dataSubmitted && (
                        loading ? (
                            <div className="overflow-auto rounded-lg bg-white laptop:h-full desktop:h-full border border-gray-dark shadow-lg shadow-gray-box col-span-2">
                                <div className="flex flex-col w-full px-8 py-6 sm:p-6">
                                    Loading...
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="overflow-hidden rounded-lg bg-white laptop:h-full desktop:h-full border border-gray-dark shadow-lg shadow-gray-box col-span-2">
                                    <div className="flex flex-col w-full px-8 py-6 sm:p-6">
                                        <div className="text-lg font-mp-bold justify-start">
                                            Laporan
                                        </div>
                                        <div className="flex flex-col p-3 gap-10 justify-center items-center laptop:flex-row laptop:justify-evenly">
                                            <div className="flex flex-col items-center text-base">
                                                <div className="font-mp-bold">Berkala</div>
                                                <LaporanBerkala
                                                    laporanBerkala={chartData.laporanBerkala}
                                                    laporanBerkalaTotal={chartData.kategoriLaporan.berkala}
                                                />
                                            </div>
                                            <div className="flex flex-col items-center text-center font-mp-bold">
                                                <div className="text-base whitespace-nowrap">TOTAL LAPORAN</div>
                                                <div className="text-xl">{chartData.totalLaporan}</div>
                                            </div>
                                            <div className="flex flex-col items-center text-base">
                                                <div className="font-mp-bold">Insidentil</div>
                                                <LaporanInsidentil
                                                    laporanInsidentil={chartData.laporanInsidentil}
                                                    laporanInsidentilTotal={chartData.kategoriLaporan.insidentil}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    )}
                </form>
        </div>
        </section>
    )
}

const FormSelect = ({ label, name, value, handleChange, options }) => (
    <div className="flex flex-col">
        <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
        <div className="mt-2">
            <select
                id={name}
                name={name}
                autoComplete="given-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
                value={value}
                onChange={handleChange}
            >
                {options.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                ))}
            </select>
        </div>
    </div>
);

const LaporanBerkala = ({ laporanBerkala, laporanBerkalaTotal }) => {
    const defaultData = {
        labels: ['Mingguan', 'Bulanan', 'Tahunan'],
        series: [0, 0, 0],
    };

    const maplaporanBerkala = (data) => ({
        'Mingguan': data.mingguan || 0,
        'Bulanan': data.bulanan || 0,
        'Tahunan': data.tahunan || 0,
    });

    const mappedData = laporanBerkala ? maplaporanBerkala(laporanBerkala) : defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanBerkalaChart laporanBerkala={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanBerkalaTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanBerkalaChart = ({ laporanBerkala }) => {
    const { labels = [], series = [] } = laporanBerkala || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04', '#1f2937'],
        labels,
        chart: { type: 'donut' },
        dataLabels: { enabled: false },
        legend: { show: false },
        plotOptions: {
            pie: { customScale: 1.0 },
            donut: { size: '85%' },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: { width: 200 },
                },
            },
        ],
    };

    const chartSeries = series.length > 0 ? series[0].data : [];

    return (
        <div className="w-full">
            <ApexCharts options={options} series={chartSeries} type="donut" />
        </div>
    );
};

const LaporanInsidentil = ({ laporanInsidentil, laporanInsidentilTotal }) => {
    const defaultData = {
        labels: ['Informasi', 'Informasi Khusus'],
        series: [0, 0],
    };

    const maplaporanInsidentil = (data) => ({
        'Informasi': data.informasi || 0,
        'Informasi Khusus': data.informasi_khusus || 0,
    });

    const mappedData = laporanInsidentil ? maplaporanInsidentil(laporanInsidentil) : defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanInsidentilChart laporanInsidentil={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanInsidentilTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanInsidentilChart = ({ laporanInsidentil }) => {
    const { labels = [], series = [] } = laporanInsidentil || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04', '#1f2937', '#8B5CF6'],
        labels,
        chart: { type: 'donut' },
        dataLabels: { enabled: false },
        legend: { show: false },
        plotOptions: {
            pie: { customScale: 1.0 },
            donut: { size: '85%' },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: { width: 200 },
                },
            },
        ],
    };

    const chartSeries = series.length > 0 ? series[0].data : [];

    return (
        <div className="w-full">
            <ApexCharts options={options} series={chartSeries} type="donut" />
        </div>
    );
};

export default RekapManualBtnclo
