import * as React from "react";
import './App.css';
import { useContext } from 'react';
import AuthContext from "./context/AuthProvider"; // Correct import
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';

import Login from "./pages/authorization/Login";
import ForgotPassword from './pages/authorization/ForgotPassword';
import ResetPassword from "./pages/authorization/ResetPassword";
import Unauthorized from "./pages/authorization/Unauthorized";
import RequireAuth from "./pages/authorization/RequireAuth";

import Dashboard from "./components/Dashboard";
import Home from "./pages/Home";
import Notifikasi from "./pages/Notifikasi";

import Polda from "./pages/polda/Polda";
import PoldaDetail from "./pages/polda/PoldaDetail";
import PoldaLaporan from "./pages/polda/PoldaLaporan";

import KemenLem from "./pages/kemen-lem/KemenLem";
import KemenLemDetail from "./pages/kemen-lem/KemenLemDetail";
import KemenForm from "./pages/kemen-lem/KemenForm";

import Satker from "./pages/satker/Satker";
import SatkerDetail from "./pages/satker/SatkerDetail";
import SatkerForm from "./pages/satker/SatkerForm";

import BatasProv from './pages/batasprov/BatasProv';
import BatasForm from "./pages/batasprov/BatasForm";

import Plbn from "./pages/plbn/Plbn";
import PlbnDetail from "./pages/plbn/PlbnDetail";

import Tpi from "./pages/tpi/Tpi";
import TpiBandara from "./pages/tpi/TpiBandara";
import TpiPelabuhan from "./pages/tpi/TpiPelabuhan";

import Laporan from "./pages/laporan/Laporan";
import LaporanDetail from "./pages/laporan/LaporanDetail";
import LaporanForm from "./pages/laporan/LaporanForm";


import Lotas from "./pages/lotas/Lotas";

import Btnclo from "./pages/btnclo/Btnclo";
import BtncloDetail from "./pages/btnclo/BtncloDetail";
import BtncloLaporan from "./pages/btnclo/BtncloLaporan";

import Admin from "./pages/administrator/Admin";

import AdminUser from "./pages/administrator/AdminUser";
import UserForm from "./pages/administrator/UserForm";

import AdminPolda from "./pages/administrator/AdminPolda";
import PoldaForm from "./pages/administrator/PoldaForm";

import AdminPolres from "./pages/administrator/AdminPolres";
import PolresForm from "./pages/administrator/PolresForm";

import AdminPolsek from "./pages/administrator/AdminPolsek";
import PolsekForm from "./pages/administrator/PolsekForm";

import AdminPlbn from "./pages/administrator/AdminPlbn";
import PlbnForm from "./pages/administrator/PlbnForm";

import Rekap from "./pages/rekap/Rekap";
import TpiForm from "./pages/tpi/TpiForm";
import RekapPolda from "./pages/rekap/RekapPolda";
import RekapPlbn from "./pages/rekap/RekapPlbn";
import RekapManual from "./pages/rekap/RekapManual";
import AdminPersonelPlbn from "./pages/administrator/AdminPersonelPlbn";
import PersonelPlbnForm from "./pages/administrator/PersonelPlbnForm";

import PanduanImbas from "./pages/PanduanImbas";
import PanduanBtnclo from "./pages/PanduanBtnclo";

import HomeBtnclo from "./pages/HomeBtnclo";
import BtncloLaporanDetail from "./pages/btnclo/BtncloLaporanDetail";

import PersonelBtncloForm from "./pages/administrator/PersonelBtncloForm";
import AdminPersonelBtnclo from "./pages/administrator/AdminPersonelBtnclo";
import AdminBtnclo from "./pages/administrator/AdminBtnclo";
import BtncloForm from "./pages/administrator/BtncloForm";
import AdminPoldaBtnclo from "./pages/administrator/AdminPoldaBtnclo";
import PoldaBtncloForm from "./pages/administrator/PoldaBtncloForm";
import BtncloLaporanForm from "./pages/btnclo/BtncloLaporanForm";
import RekapPoldaBtnclo from "./pages/rekap/RekapPoldaBtnclo";
import RekapManualBtnclo from "./pages/rekap/RekapManualBtnclo";


const ConditionalHome = ({ allowedRoles }) => {
  const { auth } = useContext(AuthContext);
  // Get access_level from auth.user
  const userRole = auth?.user?.access_level || "guest";

  // Check for access_level 4
  if (userRole === "4" || userRole === "5") {
    return <HomeBtnclo />;
  }
  // For all other valid access levels (0,1,2,3), show Home
  if (allowedRoles.includes(userRole)) {
    return <Home />;
  }
  // Fallback
  return <Home />;
};


function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public Routes */}
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        {/* Protected Routes */}
        <Route element={<RequireAuth allowedRoles={['0','1','2','3','4','5']} />}>
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="/" element={<Dashboard />} >
            <Route path="/" element={<ConditionalHome allowedRoles={['0','1','2','3','4','5']} />}/>
            <Route path="notifikasi" element={<Notifikasi />} />

            <Route path="polda" element={<Polda />} />
            <Route path="polda/:poldaName" element={<PoldaDetail />} />
            <Route path="polda/:poldaName/:polresName" element={<PoldaLaporan />} />

            <Route path="kemenlem" element={<KemenLem />} />
            <Route path="kemenlem/:kemenName" element={<KemenLemDetail />} />
            <Route path="kemenlem/addKemen" element={<KemenForm />} />
            <Route path="kemenlem/editKemen/:id" element={<KemenForm />} />

            <Route path="satker" element={<Satker />} />
            <Route path="satker/:satkerName" element={<SatkerDetail />} />
            <Route path="satker/addSatker" element={<SatkerForm />} />
            <Route path="satker/editSatker/:id" element={<SatkerForm />} />

            <Route path="batasprov" element={<BatasProv />} />
            <Route path="batasprov/addBatasprov" element={<BatasForm />} />
            <Route path="batasprov/editBatasprov/:id" element={<BatasForm />} />

            <Route path="plbn" element={<Plbn />} />
            <Route path="plbn/:plbnName" element={<PlbnDetail />} />

            <Route path="tpi" element={<Tpi />} />
            <Route path="tpi/bandara" element={<TpiBandara />} />
            <Route path="tpi/addbandara" element={<TpiForm type="bandara" title="Bandara"/>} />
            <Route path="tpi/editbandara/:id" element={<TpiForm type="bandara" title="Bandara"/>} />

            <Route path="tpi/pelabuhan" element={<TpiPelabuhan />} />
            <Route path="tpi/addpelabuhan" element={<TpiForm type="pelabuhan" title="Pelabuhan"/>} />
            <Route path="tpi/editpelabuhan/:id" element={<TpiForm type="pelabuhan" title="Pelabuhan" />} />

            <Route path="laporan" element={<Laporan />} />
            <Route path="laporan/:id" element={<LaporanDetail />} />
            <Route path="laporan/addLaporan" element={<LaporanForm />} />
            <Route path="laporan/editlaporan/:id" element={<LaporanForm />} />

            <Route path="lotas" element={<Lotas />} />

          </Route>
          
          <Route path="panduanimbas" element={<PanduanImbas />} />
          <Route path="panduanbtnclo" element={<PanduanBtnclo />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={['0','1']} />}>
          <Route path="/" element={<Dashboard />} >
            <Route path="admin" element={<Admin />}/>

            <Route path="admin/adminuser" element={<AdminUser />}/>
            <Route path="admin/adduser" element={<UserForm />}/>
            <Route path="admin/edituser/:id" element={<UserForm />} />

            <Route path="admin/adminpolda" element={<AdminPolda />}/>
            <Route path="admin/addpolda" element={<PoldaForm />}/>
            <Route path="admin/editpolda/:id" element={<PoldaForm />} />

            <Route path="admin/adminpolres" element={<AdminPolres />}/>
            <Route path="admin/addpolres" element={<PolresForm />}/>
            <Route path="admin/editpolres/:id" element={<PolresForm />} />

            <Route path="admin/adminpolsek" element={<AdminPolsek />}/>
            <Route path="admin/addpolsek" element={<PolsekForm />}/>
            <Route path="admin/editpolsek/:id" element={<PolsekForm />} />

            <Route path="admin/adminplbn" element={<AdminPlbn />}/>
            <Route path="admin/addplbn" element={<PlbnForm />}/>
            <Route path="admin/editplbn/:id" element={<PlbnForm />} />

            <Route path="admin/adminpersonel" element={<AdminPersonelPlbn />} />
            <Route path="admin/addpersonel" element={<PersonelPlbnForm />} />
            <Route path="admin/editpersonel/:id" element={<PersonelPlbnForm />} />

            <Route path="admin/adminpoldabtnclo" element={<AdminPoldaBtnclo />} />
            <Route path="admin/addpoldabtnclo" element={<PoldaBtncloForm />} />
            <Route path="admin/editpoldabtnclo/:id" element={<PoldaBtncloForm  />} />

            <Route path="admin/adminbtnclo" element={<AdminBtnclo />} />
            <Route path="admin/addbtnclo" element={<BtncloForm />} />
            <Route path="admin/editbtnclo/:id" element={<BtncloForm />} />

            <Route path="admin/adminpersonelbtnclo" element={<AdminPersonelBtnclo />} />
            <Route path="admin/addpersonelbtnclo" element={<PersonelBtncloForm />} />
            <Route path="admin/editpersonelbtnclo/:id" element={<PersonelBtncloForm />} />
          </Route>
        </Route>

        <Route element={<RequireAuth allowedRoles={['0','1','2']} />}>
          <Route path="/" element={<Dashboard />} >
            <Route path="rekap" element={<Rekap />}/>
            <Route path="rekap/rekappolda" element={<RekapPolda />}/>
            <Route path="rekap/rekapplbn" element={<RekapPlbn />}/>
            <Route path="rekap/rekapmanual" element={<RekapManual />}/>
            <Route path="rekap/rekappoldabtnclo" element={<RekapPoldaBtnclo />}/>
            <Route path="rekap/rekapmanualbtnclo" element={<RekapManualBtnclo />}/>
          </Route>
        </Route>

        <Route element={<RequireAuth allowedRoles={['0','1','2','4','5']} />}>
          <Route path="/" element={<Dashboard />} >
            <Route path="btnclo" element={<Btnclo />} />
            <Route path="btnclo/:btncloName" element={<BtncloDetail />} />

            <Route path="btncloLaporan" element={<BtncloLaporan />} />
            <Route path="btncloLaporan/addbtnclolaporan" element={<BtncloLaporanForm />} />
            <Route path="btncloLaporan/editbtnclolaporan/:id" element={<BtncloLaporanForm />} />
            <Route path="btncloLaporan/:id" element={<BtncloLaporanDetail />} />
          </Route>
        </Route>
      
      </Route>
    </Routes>
  );
}

export default App;