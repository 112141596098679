import React, { useCallback, useContext, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import axios from '../../api/axios';

const RekapPoldaBtnclo = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        start_date: '',
        end_date: '',
    });

    const [errors, setErrors] = useState({});
    const [chartData, setChartData] = useState({
        poldaBTNCLO: {},
        kategoriLaporan: {},
        laporanBerkala: {},
        laporanInsidentil: {},
        totalLaporan: 0,
        berkalaTotal: 0,
        insidentilTotal: 0,
    });

    const [dataSubmitted, setDataSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' });
    };

    const formatDate = (date) => {
        return new Date(date).toISOString().split('T')[0];
    };

    const fetchData = useCallback(async () => {
        try {
            const { start_date, end_date } = formData;
            if (!start_date || !end_date) return;

            const formattedStartDate = formatDate(start_date);
            const formattedEndDate = formatDate(end_date);

            const url = `${baseURL}/api/v1/btnclo/rekap_btnclo/${formattedStartDate}&${formattedEndDate}`;
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const data = response.data;

            setChartData({
                poldaBTNCLO: data.btnclo_polda || {},
                kategoriLaporan: data.kategori_laporan || {},
                laporanBerkala: data.laporan_berkala || {},
                laporanInsidentil: data.laporan_insidentil || {},
                totalLaporan: data.total_laporan_polda || 0,
                berkalaTotal: response.data.kategori_laporan?.berkala || 0,
                insidentilTotal: response.data.kategori_laporan?.insidentil || 0,
            });

            setLoading(false);
            setDataSubmitted(true);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }, [baseURL, formData, token]);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.start_date) {
            newErrors.start_date = 'Start date is required';
        }
        if (!formData.end_date) {
            newErrors.end_date = 'End date is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            fetchData();
        }
    };

    return (
        <section className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">Rekap POLDA BTNCLO</div>
            <div className="mt-8">
                <form onSubmit={handleSubmit} className="flex flex-col gap-8 laptop:grid laptop:grid-cols-2 laptop:gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
                        <div className="flex flex-col gap-6 px-4">
                            <div className="text-lg font-mp-bold justify-start">Range Tanggal</div>
                            <div className="flex flex-col">
                                <label htmlFor="start_date" className="block text-base leading-6 text-gray">Dari</label>
                                <div className="mt-2">
                                    <input
                                        type="date"
                                        name="start_date"
                                        id="start_date"
                                        autoComplete="given-name"
                                        value={formData.start_date}
                                        onChange={handleChange}
                                        className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-box focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors.start_date ? 'ring-red-500' : ''}`}
                                    />
                                    {errors.start_date && <span className="text-red text-sm">{errors.start_date}</span>}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="end_date" className="block text-base leading-6 text-gray">Sampai</label>
                                <div className="mt-2">
                                    <input
                                        type="date"
                                        name="end_date"
                                        id="end_date"
                                        autoComplete="given-name"
                                        value={formData.end_date}
                                        onChange={handleChange}
                                        className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-dark focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors.end_date ? 'ring-red-500' : ''}`}
                                    />
                                    {errors.end_date && <span className="text-red text-sm">{errors.end_date}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row justify-end gap-3 mt-8">
                            <button type="submit" className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg">Submit</button>
                        </div>
                    </div>
                    {dataSubmitted && (
                        loading ? (
                            <div className="overflow-auto rounded-lg bg-white laptop:h-full desktop:h-full border border-gray-dark shadow-lg shadow-gray-box col-span-2">
                                <div className="flex flex-col w-full px-8 py-6 sm:p-6">
                                    Loading...
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="overflow-auto rounded-lg bg-white laptop:h-full desktop:h-full border border-gray-dark shadow-lg shadow-gray-box col-span-2">
                                    <div className="flex flex-col w-full px-8 py-6 sm:p-6">
                                        <div className="text-lg font-mp-bold justify-start">Polda BTNCLO</div>
                                        <PoldaBtncloChart btncloPolda={chartData.poldaBTNCLO} />
                                    </div>
                                </div>
                                <div className="overflow-hidden rounded-lg bg-white laptop:h-full desktop:h-full border border-gray-dark shadow-lg shadow-gray-box col-span-2">
                                    <div className="flex flex-col w-full px-8 py-6 sm:p-6">
                                        <div className="text-lg font-mp-bold justify-start">Laporan</div>
                                        <div className="flex flex-col p-3 gap-10 justify-center items-center laptop:flex-row laptop:justify-evenly">
                                            <div className="flex flex-col items-center text-base">
                                                <div className="font-mp-bold">Berkala</div>
                                                <LaporanBerkala
                                                    laporanBerkala={chartData.laporanBerkala}
                                                    laporanBerkalaTotal={chartData.kategoriLaporan.berkala}
                                                />
                                            </div>
                                            <div className="flex flex-col items-center text-center font-mp-bold">
                                                <div className="text-base whitespace-nowrap">TOTAL LAPORAN</div>
                                                <div className="text-xl">{chartData.totalLaporan}</div>
                                            </div>
                                            <div className="flex flex-col items-center text-base">
                                                <div className="font-mp-bold">Insidentil</div>
                                                <LaporanInsidentil
                                                    laporanInsidentil={chartData.laporanInsidentil}
                                                    laporanInsidentilTotal={chartData.kategoriLaporan.insidentil}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    )}
                </form>
            </div>
        </section>
    );
};

const PoldaBtncloChart = ({ btncloPolda }) => {
    const defaultData = {
        labels: [
            'Aceh', 'Sumatera Utara', 'Riau', 'Kepulauan Riau', 'D.I. Yogyakarta', 'Jawa Timur',
            'Kalimantan Timur', 'Kalimantan Barat', 'Kalimantan Utara', 'Sulawesi Utara',
            'Bali', 'NTT', 'Papua',
        ],
        series: Array(13).fill(0),
    };

    const mapPoldaData = (data) => ({
        'Aceh': data.aceh || 0,
        'Sumatera Utara': data.sumatera_utara || 0,
        'Riau': data.riau || 0,
        'Kepulauan Riau': data.kepulauan_riau || 0,
        'D.I. Yogyakarta': data.yogya || 0,
        'Jawa Timur': data.jawa_timur || 0,
        'Kalimantan Timur': data.kalimantan_timur || 0,
        'Kalimantan Barat': data.kalimantan_barat || 0,
        'Kalimantan Utara': data.kalimantan_utara || 0,
        'Sulawesi Utara': data.sulawesi_utara || 0,
        'Bali': data.bali || 0,
        'NTT': data.ntt || 0,
        'Papua': data.papua || 0,
    });

    const mappedData = btncloPolda ? mapPoldaData(btncloPolda) : defaultData;

    const labels = defaultData.labels; // Ensure consistent order of Polda names
    const series = [{ data: labels.map(label => mappedData[label] || 0) }];

    return (
        <div className="flex p-5">
            <BarChart labels={labels} series={series} />
        </div>
    );
};

const BarChart = ({ labels = [], series = [] }) => {
    const options = {
        xaxis: {
            categories: labels, // Set Polda names as x-axis categories
            labels: {
                rotate: -45, // Rotate labels for better readability
                style: {
                    fontSize: '12px',
                    fontWeight: 600,
                },
            },
        },
        colors: ['#228159'],
        chart: {
            type: 'bar',
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        stroke: {
            width: 3,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: { width: 200 },
                },
            },
        ],
    };

    return (
        <div className="h-[20%] w-full">
            <ApexCharts options={options} series={series} type="bar" />
        </div>
    );
};


const LaporanBerkala = ({ laporanBerkala, laporanBerkalaTotal }) => {
    const defaultData = {
        labels: ['Mingguan', 'Bulanan', 'Tahunan'],
        series: [0, 0, 0],
    };

    const maplaporanBerkala = (data) => ({
        'Mingguan': data.mingguan || 0,
        'Bulanan': data.bulanan || 0,
        'Tahunan': data.tahunan || 0,
    });

    const mappedData = laporanBerkala ? maplaporanBerkala(laporanBerkala) : defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanBerkalaChart laporanBerkala={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanBerkalaTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanBerkalaChart = ({ laporanBerkala }) => {
    const { labels = [], series = [] } = laporanBerkala || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04', '#1f2937'],
        labels,
        chart: { type: 'donut' },
        dataLabels: { enabled: false },
        legend: { show: false },
        plotOptions: {
            pie: { customScale: 1.0 },
            donut: { size: '85%' },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: { width: 200 },
                },
            },
        ],
    };

    const chartSeries = series.length > 0 ? series[0].data : [];

    return (
        <div className="w-full">
            <ApexCharts options={options} series={chartSeries} type="donut" />
        </div>
    );
};

const LaporanInsidentil = ({ laporanInsidentil, laporanInsidentilTotal }) => {
    const defaultData = {
        labels: ['Informasi', 'Informasi Khusus'],
        series: [0, 0],
    };

    const maplaporanInsidentil = (data) => ({
        'Informasi': data.informasi || 0,
        'Informasi Khusus': data.informasi_khusus || 0,
    });

    const mappedData = laporanInsidentil ? maplaporanInsidentil(laporanInsidentil) : defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanInsidentilChart laporanInsidentil={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanInsidentilTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanInsidentilChart = ({ laporanInsidentil }) => {
    const { labels = [], series = [] } = laporanInsidentil || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04', '#1f2937', '#8B5CF6'],
        labels,
        chart: { type: 'donut' },
        dataLabels: { enabled: false },
        legend: { show: false },
        plotOptions: {
            pie: { customScale: 1.0 },
            donut: { size: '85%' },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: { width: 200 },
                },
            },
        ],
    };

    const chartSeries = series.length > 0 ? series[0].data : [];

    return (
        <div className="w-full">
            <ApexCharts options={options} series={chartSeries} type="donut" />
        </div>
    );
};

export default RekapPoldaBtnclo;