import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../api/axios';

const PersonelBtncloForm = () => {
  const baseURL = useContext(ApiContext);
  const [satkerOptions, setSatkerOptions] = useState([]);
  const [btncloOptions, setBtncloOptions] = useState([]);
  const [formData, setFormData] = useState({ polda: '', btnclo: '', role: '', jabatan: '', nama: '', kontak: '' });
  const [isEditMode, setIsEditMode] = useState(false);

  const { auth } = useContext(AuthContext);
  const token = auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [satkerResponse, btncloResponse] = await Promise.all([
          axios.get(`${baseURL}/api/v1/btnclo/dropdown_polda`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          id
            ? axios.get(`${baseURL}/api/v1/btnclo/personel/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
              })
            : Promise.resolve(null),
        ]);
  
        // Filter out "DIVHUBINTER" from the response data
        const filteredPolda = satkerResponse.data.polda.filter((polda) => polda.polda !== 'DIVHUBINTER');
  
        setSatkerOptions([
          { value: '', label: '-- Pilih Opsi --' },
          ...filteredPolda.map((polda) => ({
            value: polda.polda,
            label: polda.polda,
          })),
        ]);
  
        if (btncloResponse) {
          setIsEditMode(true);
          setFormData({
            polda: btncloResponse.data.personel_btnclo.polda || '',
            btnclo: btncloResponse.data.personel_btnclo.btnclo || '',
            role: btncloResponse.data.personel_btnclo.role || '',
            jabatan: btncloResponse.data.personel_btnclo.jabatan || '',
            nama: btncloResponse.data.personel_btnclo.nama || '',
            kontak: btncloResponse.data.personel_btnclo.kontak || '',
          });
        }
  
        // Fetch BTNCLo options dynamically based on selected satker
        if (formData.polda) {
          const btncloDropdownResponse = await axios.get(
            `${baseURL}/api/v1/btnclo/dynamic_btnclo/${encodeURIComponent(formData.polda)}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
  
          const filteredBtnclo = btncloDropdownResponse.data.btnclo || [];
          setBtncloOptions([
            { value: '', label: '-- Pilih Opsi --' },
            ...filteredBtnclo.map((btnclo) => ({
              value: btnclo.btnclo || btnclo, // Ensure value is a string or primitive
              label: btnclo.btnclo || btnclo,
            })),
          ]);
        } else {
          setBtncloOptions([{ value: '', label: '-- Pilih POLDA Terlebih Dahulu --' }]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [token, baseURL, id, formData.polda]);  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiEndpoint = isEditMode ? `${baseURL}/api/v1/btnclo/update_personel/${id}` : `${baseURL}/api/v1/btnclo/store_personel`;

    try {
      const response = await axios.post(apiEndpoint, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('Personel BTNCLO saved successfully:', response.data);
      navigate('/admin/adminpersonelbtnclo');
    } catch (error) {
      console.error('Error saving BTNCLO:', error.response ? error.response.data : error.message);
    }
  };

  const handleCancel = () => {
    navigate('/admin/adminpersonelbtnclo');
  };

  return (
    <div className="mt-8">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
            <div className="text-lg font-bold">{isEditMode ? 'Edit Personel BTNCLO' : 'Input Personel BTNCLO'}</div>
            <div className="flex flex-col gap-6 px-4">
              <FormSelect label="POLDA" name="polda" value={formData.polda} handleChange={handleChange} options={satkerOptions} />
              <FormSelect label="Nama BTNCLO" name="btnclo" value={formData.btnclo} handleChange={handleChange} options={btncloOptions} />
              <FormInput label="Role" type="text" name="role" value={formData.role} handleChange={handleChange} />
              <FormInput label="Jabatan" type="text" name="jabatan" value={formData.jabatan} handleChange={handleChange} />
              <FormInput label="Nama" type="text" name="nama" value={formData.nama} handleChange={handleChange} />
              <FormInput label="Kontak" type="text" name="kontak" value={formData.kontak} handleChange={handleChange} />
            </div>
            <div className="flex flex-row justify-end gap-3 mt-8">
              <button type="button" onClick={handleCancel} className="bg-red text-white flex justify-center items-center h-12 w-28 rounded-lg">
                Cancel
              </button>
              <button type="submit" className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const FormInput = ({ label, type, name, value, handleChange }) => (
  <div className="flex flex-col">
    <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
    <div className="mt-2">
      <input
        type={type}
        name={name}
        id={name}
        autoComplete="off"
        className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-dark focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
        value={value}
        onChange={handleChange}
      />
    </div>
  </div>
);

const FormSelect = ({ label, name, value, handleChange, options }) => (
  <div className="flex flex-col">
    <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
    <div className="mt-2">
      <select
        id={name}
        name={name}
        autoComplete="off"
        className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-dark focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
        value={value}
        onChange={handleChange}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  </div>
);

export default PersonelBtncloForm;
