import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

// Register the Arial font from the local folder
Font.register({
  family: 'Arial',
  src: '/fonts/Arial.woff', // Adjusted to the public folder path
});

// Define the PdfDocument component
const PdfDocument = ({ laporan, kontenLabels, tipe }) => {
  const replaceWords = (text) => {
    const replacements = {
      "SUMATERA UTARA": "SUMUT",
      "KEPULAUAN RIAU": "KEPRI",
      "D.I. YOGYAKARTA": "DIY",
      "JAWA TIMUR": "JATIM",
      "KALIMANTAN BARAT": "KALBAR",
      "KALIMANTAN UTARA": "KALTARA",
      "KALIMANTAN TIMUR": "KALTIM",
      "SULAWESI UTARA": "SULUT",
    };

    let modifiedText = text;
    Object.entries(replacements).forEach(([key, value]) => {
      const regex = new RegExp(key, 'g');
      modifiedText = modifiedText.replace(regex, value);
    });

    return modifiedText;
  };

  const contentSections = [
    { title: `I. ${kontenLabels.konten_1}`, content: laporan.konten_1 || '-' },
    { title: `II. ${kontenLabels.konten_2}`, content: laporan.konten_2 || '-' },
    { title: `III. ${kontenLabels.konten_3}`, content: laporan.konten_3 || '-' },
  ];

  const splitContent = (sections, maxContentHeight) => {
    const pages = [];
    const fixedHeight = 50; // Reserved height for "RAHASIA" text and page numbers
    const headerHeight = 30; // Reserved height for the header on the first page

    let currentPageContent = [];
    let currentPageHeight = fixedHeight;

    // Function to sanitize content by removing extra line breaks and trimming spaces
    const sanitizeContent = (content) =>
        content.replace(/\n\s*\n/g, '\n').trim();

    // Combine all sections into a single content string with titles
    const combinedContent = sections
        .map((section) => `${section.title}\n${sanitizeContent(section.content)}`)
        .join("\n\n"); // Add spacing between sections

    const words = combinedContent.split(" ");
    let tempContent = "";
    let tempHeight = 0;

    words.forEach((word) => {
        const wordHeight = 1.5; // Estimated height per word

        // Check if adding the word exceeds max content height
        if (
            currentPageHeight +
                (pages.length === 0 ? headerHeight : 0) +
                tempHeight +
                wordHeight >
            maxContentHeight
        ) {
            // Add current content to the page
            if (tempContent) {
                currentPageContent.push(tempContent.trim());
            }
            pages.push(currentPageContent);

            // Reset for the next page
            currentPageContent = [];
            tempContent = word; // Start new content
            tempHeight = wordHeight;
            currentPageHeight = fixedHeight;
        } else {
            // Add word to the current content
            tempContent += (tempContent ? " " : "") + word;
            tempHeight += wordHeight;
        }
    });

    // Push remaining content to the last page
    if (tempContent) {
        currentPageContent.push(tempContent.trim());
    }
    if (currentPageContent.length > 0) {
        pages.push(currentPageContent);
    }

    return pages;
};
  
  const contentPages = splitContent(contentSections, 500); // Adjusted logic  

  return (
    <Document>
      {contentPages.map((pageContent, pageIndex) => (
        <Page size="A4" style={styles.page} key={pageIndex}>
          <View style={styles.header}>
            <Text style={styles.confidential}>RAHASIA</Text>
            <Text style={styles.number}>{pageIndex + 1}</Text>
          </View>
          <View style={styles.content}>
            {pageIndex === 0 && (
              <View>
                <View style={styles.institutionHeader}>
                  {tipe === "KOORDINATOR" && (
                    <Text>
                      KEPOLISIAN NEGARA REPUBLIK INDONESIA{'\n'}
                      <Text>
                        DAERAH {laporan.kop || '-'}{'\n'}
                      </Text>
                      <Text style={[styles.underlined]}>
                        KOORDINATOR BTNCLO {replaceWords(laporan.kop || '-')}
                      </Text>
                    </Text>
                  )}
                  {tipe === "BTNCLO" && (
                    <Text>
                      KEPOLISIAN NEGARA REPUBLIK INDONESIA{'\n'}
                      DAERAH{' '}
                      {laporan.polda
                        ? laporan.polda
                            .split(' ')
                            .slice(2)
                            .join(' ')
                            .toUpperCase()
                        : '-'}
                      {'\n'}
                      <Text style={[styles.underlined]}>
                        BTNCLO {laporan.kop || '-'}
                      </Text>
                    </Text>
                  )}
                </View>
                <View style={styles.titleSection}>
                  <Text style={[styles.reportTitle, styles.underlined]}>
                    LAPORAN {laporan.jenisLaporan}
                  </Text>
                  <View style={styles.headerSubject}>
                    <Text style={styles.text}>
                      Nomor : {replaceWords(laporan.nomor || '-')}
                    </Text>
                    <Text style={styles.text}>
                      Bidang: {laporan.bidang ? laporan.bidang.toUpperCase() : '-'}
                    </Text>
                  </View>
                  <Text style={styles.text}>Tentang:</Text>
                  <Text style={styles.boldText}>
                    {laporan.perihal ? laporan.perihal.toUpperCase() : '-'}
                  </Text>
                </View>
              </View>
            )}
            {pageContent.map((paragraph, index) => (
              <View
                key={index}
                style={[
                  styles.section,
                  index > 0 && { marginTop: 20 }, // Add margin between sections
                ]}
              >
                <Text style={styles.sectionContent}>{paragraph}</Text>
              </View>
            ))}
          </View>
          <View fixed style={styles.footer}>
            {pageIndex === contentPages.length - 1 && (
              <View style={styles.footerContent}>
                <View style={styles.signSection}>
                  <Text style={styles.footerTextSign}>{laporan.sign || '-'}</Text>
                  <View style={styles.signText}>
                    <Text style={styles.footerText}>
                      Autentikasi{'\n'}
                      {laporan.created_by || '-'}
                    </Text>
                  </View>
                </View>
                <View style={styles.distributionSection}>
                  <Text style={styles.distributionTitle}>Distribusi:</Text>
                  <Text style={styles.distributionTitle}>1. Kadivhubinter</Text>
                  <Text style={styles.distributionTitle}>
                    2. Kapolda{' '}
                    {laporan.polda
                      ? laporan.polda
                          .split(' ')
                          .slice(2)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(' ')
                      : '-'}
                  </Text>
                  <Text style={styles.distributionTitle}>3. SES NCB</Text>
                  <Text style={styles.distributionTitle}>4. Kabag Lotas</Text>
                </View>
              </View>
            )}
          </View>
          <View fixed style={styles.bottomConfidential}>
            <Text style={styles.confidential}>RAHASIA</Text>
            <Text style={styles.number}>{pageIndex + 1}</Text>
          </View>
        </Page>
      ))}
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingTop: 56.7,
    paddingRight: 56.7,
    paddingLeft: 56.7,
    paddingBottom: 56.7,
    fontSize: 11,
    fontFamily: 'Arial',
    position: 'relative',
  },
  header: {
    position: 'absolute',
    top: 35.4375,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  confidential: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textDecoration: 'underline',
  },
  number: {
    marginTop: 5,
    fontSize: 10,
  },
  content: {
    paddingTop: 20,
    paddingBottom: 10,
    justifyContent: 'flex-start', // Ensures content starts at the top
  },
  institutionHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'center',
    marginBottom: 20,
  },
  underlined: {
    textDecoration: 'underline',
  },
  titleSection: {
    marginBottom: 20,
    minHeight: 80, // Ensure enough space for titles
    textAlign: 'center',
  },
  reportTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: 3,
  },
  headerSubject:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'start',
    marginTop: 5,
    marginBottom: 10
  },
  text: {
    marginBottom: 5,
  },
  boldText: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  section: {
    marginBottom: 20,
    minHeight: 50, // Prevent sections from collapsing
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  sectionContent: {
    textAlign: 'justify'
  },
  footer: {
    marginTop: 10,
    paddingTop: 20,
  },
  footerContent: {
    marginBottom: 5,
  },
  signSection: {
    marginBottom: 5,
  },
  footerTextSign: {
    paddingBottom: 50,
    textAlign: 'right',
  },
  signText: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  footerText: {
    marginBottom: 5,
    textAlign: 'center',
  },
  distributionSection: {
    marginTop: 5,
    textAlign: 'left',
  },
  distributionTitle: {
    fontWeight: 'bold',
    marginBottom: 2,
  },
  bottomConfidential: {
    position: 'absolute',
    bottom: 35.4375,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
});

export default PdfDocument;